import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

const Footer = () => {
    const [cat, setCat] = useState([]);
    const [social, setSocial] = useState([]);
    const getData = async () => {
        const { data: cat } = await axios.get('/api/cat?limit=true');
        setCat(cat)
        const { data: social } = await axios.get('/api/social');
        setSocial(social)
    }
    useEffect(() => {
        axios.put('/api/visitor');
        getData();
    }, []);

    function c2k(number) {
        const kn = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
        const nstr = String(number);
        let knr = "";

        for (let i = 0; i < nstr.length; i++) {
            const digit = parseInt(nstr[i]);
            if (!isNaN(digit)) {
                knr += kn[digit];
            } else {
                knr += nstr[i];
            }
        }
        return knr;
    }

    return (
        <footer>
            <div className="footer__area footer-bg">
                <div className="footer__top pt-190 pb-40">
                    <div className="container">
                        <div className="row">
                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-6">
                                <div className="footer__widget mb-50">
                                    <div className="footer__widget-head mb-22">
                                        <div className="footer__logo">
                                            <Link to="/" className="section__title text-warning">زانکۆ&nbsp;
                                                <span className="yellow-bg">بادینان<img src="../../assets/img/yellow-bg.png" alt="sam" /></span>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="footer__widget-body">
                                        <p>زانکوبادینان ئێکەمین ژێدەر بو قوتابیان</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xxl-2 offset-xxl-1 col-xl-2 offset-xl-1 col-lg-3 offset-lg-0 col-md-2 offset-md-1 col-sm-5 offset-sm-1">
                                <div className="footer__widget mb-50">
                                    <div className="footer__widget-head mb-22">
                                        <h3 className="footer__widget-title">پشک</h3>
                                    </div>
                                    <div className="footer__widget-body">
                                        <div className="footer__link">
                                            <ul>
                                                {cat.map((item, index) => {
                                                    return (
                                                        <li key={index}><Link to={`/cat/${item.id}`}>{item.name}</Link></li>
                                                    )
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xxl-2 col-xl-2 col-lg-2 offset-lg-0 col-md-3 offset-md-1 col-sm-6">
                                <div className="footer__widget mb-50">
                                    <div className="footer__widget-head mb-22">
                                        <h3 className="footer__widget-title">لینک</h3>
                                    </div>
                                    <div className="footer__widget-body">
                                        <div className="footer__link">
                                            <ul>
                                                <li><Link to="/">دەستپێک</Link></li>
                                                <li><Link to="/posts">پێزانین</Link></li>
                                                <li><Link to="/courses">کۆرس</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-5 col-sm-6">
                                <div className="footer__widget footer__pl-70 mb-50">
                                    <div className="footer__widget-head mb-22">
                                        <h3 className="footer__widget-title">سۆشیال</h3>
                                    </div>
                                    <div className="footer__social">
                                        <ul>
                                            {social.map((item, index) => {
                                                return (
                                                    <li key={index}><a href={item.social_link} target="_blank" rel="noreferrer"><div dangerouslySetInnerHTML={{ __html: item.social_icon }} /></a></li>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer__bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-xxl-12">
                                <div className="footer__copyright text-center">
                                    <p><span className="text-warning">زانکوبادینان</span> &copy; {c2k(new Date().getFullYear())}، هاتیە پرۆگرامکرن ژ لایێ <a href="https://www.facebook.com/npmsam" target="_blank" rel="noreferrer">سەمیر محەمەد</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}


export default Footer;