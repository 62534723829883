import React from 'react';
import { createBrowserRouter } from "react-router-dom";
import ErrorPage from './Error';
import APP from '../App';
import Home from './Home';
import Courses from './Courses';
import Posts from './Posts';
import Post from './Post';
import Category from './Category';
import Contact from './Contact';
import Submit from './Submit';
import Submition from './Submition';
import Quiz from './Quiz';
import StartQuiz from './Quiz/StartQuiz';
import SubmitQuiz from './Quiz/SubmitQuiz';
import SubmitionResult from './submitionResult';

const Routes = createBrowserRouter([
    {
        path: "/",
        element: <APP><Home /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/courses",
        element: <APP><Courses /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/submit/:id",
        element: <APP><Submit /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/posts",
        element: <APP><Posts /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/post/:id",
        element: <APP><Post /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/cat/:id",
        element: <APP><Category /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/contact",
        element: <APP><Contact /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/submition",
        element: <APP><Submition /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/submition-result",
        element: <APP><SubmitionResult /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/startx",
        element: <APP><StartQuiz /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/quizx/:id",
        element: <Quiz />,
        errorElement: <ErrorPage />,
    },
    {
        path: "/start",
        element: <APP><SubmitQuiz /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/quiz/:id",
        element: <StartQuiz />,
        errorElement: <ErrorPage />,
    },
]
);

export default Routes;