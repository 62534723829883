import { Link, useRouteError } from "react-router-dom";
export default function ErrorPage() {
    const error = useRouteError();
    return (
        <section className="error__area pt-200 pb-200">
            <div className="container">
                <div className="row">
                    <div className="col-xxl-8 offset-xxl-2 col-xl-8 offset-xl-2 col-lg-10 offset-lg-1">
                        <div className="error__item text-center">
                            <div className="error__thumb mb-45">
                                <img src="../../assets/img/error.png" alt="error" />
                            </div>
                            <div className="error__content">
                                <h3 className="error__title">لاپەڕ نەهاتە دیتن !</h3>
                                <p>{error.statusText || error.message}</p>
                                <Link to="/" className="e-btn e-btn-3 e-btn-4">زڤڕین بۆ دەستپێک</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    );
}