import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

const Posts = () => {
    const [post, setPost] = useState([]);
    const title = document.title = "زانکۆبادینان، ئێکەمین ڕێبەریا قوتابیان ل کوردستان"
    const getData = async () => {
        const { data: post } = await axios.get('/api/posts');
        setPost(post)
    }

    useEffect(() => {
        getData();
    }, [title])

    return (
        <main>
            <section className="events__area pt-115 pb-120 p-relative">
                <div className="events__shape">
                    <img className="events-1-shape" src="../../assets/img/events-shape.png" alt="sam" />
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-4 offset-xxl-4">
                            <div className="section__title-wrapper mb-60 text-center">
                                <h2 className="section__title">پێزانینێن <span className="yellow-bg">گرنگ<img src="../../assets/img/yellow-bg.png" alt="sam" /></span></h2>
                                <p>مە {post.length} پێزانینێن گرنگ بوتە بەرهەڤکری.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {post.map((item, index) => {
                            return (
                                <div key={index} className="col-xxl-10 offset-xxl-1 col-xl-10 offset-xl-1 col-lg-10 offset-lg-1">
                                    <div className="events__item mb-10 hover__active">
                                        <div className="events__item-inner d-sm-flex align-items-center justify-content-between white-bg">
                                            <div className="events__content">
                                                <div className="events__meta">
                                                    <span>{new Date(item.created).toLocaleDateString()}</span>
                                                </div>
                                                <h3 className="events__title"><Link to={`/post/${item.id}`}>{item.title}</Link></h3>
                                            </div>
                                            <div className="events__more">
                                                <Link to={`/post/${item.id}`} className="link-btn">
                                                    زێدەتر
                                                    <i className="far fa-arrow-left" />
                                                    <i className="far fa-arrow-left" />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section>
        </main>
    );
}

export default Posts;