import Header from "./Components/Layout/Header";
import Footer from "./Components/Layout/Footer";
import * as icon from 'react-feather';
const APP = ({ children }) => {
  return (
    <>
      <Header icon={icon} />
      <div className="sidebar__area">
        <div className="sidebar__wrapper">
          <div className="sidebar__close">
            <button className="sidebar__close-btn" id="sidebar__close-btn">
              <span><i className="fal fa-times" /></span>
              <span>close</span>
            </button>
          </div>
          <div className="sidebar__content">
            <div className="logo mb-40">
              <a href="/" className="section__title">زانکۆ&nbsp;
                <span className="yellow-bg">بادینان<img src="../../assets/img/yellow-bg.png" alt="sam" /></span>
              </a>
            </div>
            <div className="mobile-menu fix" />
          </div>
        </div>
      </div>
      <div className="body-overlay" />
      {children}
      <Footer icon={icon} />
    </>
  );
};

export default APP;