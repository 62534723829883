import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import commaNumber from "comma-number";

const Home = () => {
    const [intro, setIntro] = useState([]);
    const [cat, setCat] = useState([]);
    const [course, setCourse] = useState([]);
    const [staff, setStaff] = useState([]);
    const [ads, setAds] = useState([]);
    const [post, setPost] = useState([]);
    const [visitor, setVisitor] = useState([]);
    const title = document.title = "زانکۆبادینان، ئێکەمین ڕێبەریا قوتابیان ل کوردستان";

    const getData = async () => {
        const { data: intro } = await axios.get('/api/intro');
        setIntro(intro)
        const { data: cat } = await axios.get('/api/cat');
        setCat(cat)
        const { data: course } = await axios.get('/api/course');
        setCourse(course)
        const { data: staff } = await axios.get('/api/staff');
        setStaff(staff)
        const { data: ads } = await axios.get('/api/ads');
        setAds(ads)
        const { data: post } = await axios.get('/api/posts?limit=true');
        setPost(post)
        const { data: visitor } = await axios.get('/api/visitor');
        setVisitor(visitor.visitors)
    }

    useEffect(() => {
        getData();
    }, [title])
    const colors = ["sky-blue", "green", "orange", "pink"]

    return (
        <main>
            <section className="hero__area hero__height d-flex align-items-center grey-bg-2 p-relative">
                <div className="hero__shape">
                    <img className="hero-1-circle" src="../../assets/img/hero-1-circle.png" alt="sam" />
                    <img className="hero-1-circle-2" src="../../assets/img/hero-1-circle-2.png" alt="sam" />
                    <img className="hero-1-dot-2" src="../../assets/img/hero-1-dot-2.png" alt="sam" />
                </div>
                <div className="container">
                    <div className="hero__content-wrapper mt-90">
                        <div className="row align-items-center">
                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                                <div className="hero__content p-relative z-index-1">
                                    <h3 className="hero__title">
                                        {intro.title}
                                        <span className="yellow-shape">{intro.caption}
                                            <img src="../../assets/img/yellow-bg.png" alt="yellow-shape" />
                                        </span>
                                    </h3>
                                    <p>{intro.content}</p>
                                    <div className="row">
                                        <div className="col-6">
                                            <a href={`/${intro.linkto}`} className="e-btn">زێدەتر بزانە</a>
                                        </div>
                                        <div className="col-6">
                                            <div className="footer__social">
                                                <ul>
                                                    <li>
                                                        <a href="https://www.snapchat.com/add/zankobadinan" target="_blank" rel="noreferrer">
                                                            <div>
                                                                <i className="fab fa-snapchat-square text-warning fa-3x" />
                                                            </div>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="https://www.facebook.com/zanko.badinan" target="_blank" rel="noreferrer">
                                                            <div>
                                                                <i className="fab fa-facebook-square text-primary fa-3x" />
                                                            </div>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="https://www.instagram.com/zankobadinan/" target="_blank" rel="noreferrer">
                                                            <div>
                                                                <i className="fab fa-instagram text-danger fa-3x" />
                                                            </div>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="https://www.tiktok.com/@zanko_badinan" target="_blank" rel="noreferrer">
                                                            <div>
                                                                <i className="fab fa-tiktok text-dark fa-3x" />
                                                            </div>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h4 className="mt-20">
                                    ژمارا سەردان {commaNumber(visitor)}
                                </h4>
                            </div>
                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                                <div className="hero__thumb d-flex p-relative">
                                    <div className="hero__thumb-shape">
                                        <img className="hero-1-dot" src="../../assets/img/hero-1-dot.png" alt="sam" />
                                        <img className="hero-1-circle-3" src="../../assets/img/hero-1-circle-3.png" alt="sam" />
                                        <img className="hero-1-circle-4" src="../../assets/img/hero-1-circle-4.png" alt="sam" />
                                    </div>
                                    <div className="hero__thumb-big mr-30">
                                        <img src={`../../${intro.max_image}`} alt={intro.alt_image} />
                                        <div className="hero__quote hero__quote-animation">
                                            <h4>{intro.alt_image}</h4>
                                        </div>
                                    </div>
                                    <div className="hero__thumb-sm mt-50 d-none d-lg-block">
                                        <img src={`../../${intro.min_image}`} alt={intro.alt_image} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="category__area pt-120 pb-70">
                <div className="container">
                    <div className="row align-items-end">
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-8">
                            <div className="section__title-wrapper mb-45">
                                <h2 className="section__title">  گرنگترین <span className="yellow-bg">پشک <img src="../../assets/img/yellow-bg-2.png" alt="sam" /></span>
                                </h2>
                            </div>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-4">
                            <div className="category__more mb-50 float-md-end fix">
                                <Link to="/posts" className="link-btn">
                                    بابەتێن زێدەتر
                                    <i className="far fa-arrow-left" />
                                    <i className="far fa-arrow-left" />
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {cat.map((item, index) => {
                            return (
                                <div key={index} className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                    <Link to={`/cat/${item.id}`}>
                                        <div className="category__item mb-30 transition-3 d-flex align-items-center">
                                            <div className="category__icon mr-30">
                                                <svg viewBox="0 0 512 512">
                                                    <g>
                                                        <path className="st0" d="M178.7,492H120c-55.2,0-100-44.8-100-100V120C20,64.8,64.8,20,120,20h58.7C123.7,20,81,64.8,81,120v272   C81,447.2,123.7,492,178.7,492z M355.5,204.8l18.9-85.5c4.8-24.1,16.7-46.3,34.1-63.7l35.4-35.4c-15.1-1.4-30.7,3.7-42.3,15.3   l-61.1,61.1c-17.4,17.4-29.3,39.6-34.1,63.7L295,217l56.7-11.3C352.9,205.4,354.2,205.1,355.5,204.8L355.5,204.8z" />
                                                        <path className="st1" d="M299,512H120C53.8,512,0,458.2,0,392V120C0,53.8,53.8,0,120,0h183c11,0,20,9,20,20s-9,20-20,20H120   c-44.1,0-80,35.9-80,80v272c0,44.1,35.9,80,80,80h179c44.1,0,80-35.9,80-80V272c0-11,9-20,20-20s20,9,20,20v120   C419,458.2,365.2,512,299,512z M298.9,236.6l56.7-11.3c28.1-5.6,53.7-19.3,73.9-39.6l61.1-61.1c28.5-28.5,28.5-74.8,0-103.2   c-28.5-28.5-74.8-28.5-103.2,0l-61.1,61.1c-20.3,20.3-33.9,45.8-39.6,73.9l-11.3,56.7c-1.3,6.6,0.7,13.3,5.5,18.1   c3.8,3.8,8.9,5.9,14.1,5.9C296.3,237,297.6,236.9,298.9,236.6L298.9,236.6z M462.4,49.7c6.2,6.2,9.7,14.5,9.7,23.3   s-3.4,17.1-9.7,23.3l-61.1,61.1c-14.7,14.7-33.2,24.6-53.5,28.6l-27.3,5.4l5.4-27.3c4.1-20.3,14-38.8,28.6-53.5l61.1-61.1   c6.2-6.2,14.5-9.7,23.3-9.7S456.1,43.4,462.4,49.7L462.4,49.7z" />
                                                        <path className="st2" d="M319,352H101c-11,0-20-9-20-20s9-20,20-20h218c11,0,20,9,20,20S330.1,352,319,352z M211,387   c-13.8,0-25,11.2-25,25s11.2,25,25,25s25-11.2,25-25S224.8,387,211,387z" />
                                                    </g>
                                                </svg>
                                            </div>
                                            <div className="category__content">
                                                <h4 className="category__title">
                                                    {item.name}
                                                </h4>
                                                <p>{item.description}</p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            )
                        })}

                    </div>
                </div>
            </section>
            <section className="course__area pt-115 pb-120 grey-bg">
                <div className="container">
                    <div className="row align-items-end">
                        <div className="col-xxl-5 col-xl-6 col-lg-6">
                            <div className="section__title-wrapper mb-60">
                                <h2 className="section__title">نوێترین <span className="yellow-bg">کۆرس<img src="../../assets/img/yellow-bg-2.png" alt="sam" /></span></h2>
                            </div>
                        </div>
                    </div>
                    <div className="row grid">
                        {course.map((item, index) => {
                            return (
                                <div key={index} className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 grid-item cat1 cat2 cat4">
                                    <div className="course__item white-bg mb-30 fix">
                                        <div className="course__thumb w-img p-relative fix">
                                            <Link to={`/submit/${item.id}`}>
                                                <img src={`../../${item.cover}`} alt={item.subject} />
                                            </Link>
                                            <div className="course__tag">
                                                <Link to={`/submit/${item.id}`} className={colors[index % colors.length]}>{item.subject}</Link>
                                            </div>
                                        </div>
                                        <div className="course__content">
                                            <div className="course__meta d-flex align-items-center justify-content-between">
                                                <div className="course__rating">
                                                    <span><i className="far fa-school" />{item.school}</span>
                                                </div>
                                            </div>
                                            <h3 className="course__title"><Link to={`/submit/${item.id}`}>{item.description}</Link></h3>
                                            <div className="course__teacher d-flex align-items-center">
                                                <div className="course__teacher-thumb mr-15">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" /><circle cx={12} cy={7} r={4} /></svg>
                                                </div>
                                                <h6><Link to={`/submit/${item.id}`}>{item.teacher}</Link></h6>
                                            </div>
                                        </div>
                                        <div className="course__more d-flex justify-content-between align-items-center">
                                            <div className="course__status">
                                                <span className={colors[index % colors.length]}>{commaNumber(item.price)} د.ع</span>
                                            </div>
                                            <div className="course__btn">
                                                <Link to={`/submit/${item.id}`} className="link-btn">
                                                    پشکداریکرن
                                                    <i className="far fa-arrow-left" />
                                                    <i className="far fa-arrow-left" />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section>
            <section className="teacher__area pt-115 pb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-6 offset-xxl-3">
                            <div className="section__title-wrapper text-center mb-60">
                                <h2 className="section__title">ستافێ
                                    <span className="yellow-bg">  زانکوبادینان
                                        <img src="../../assets/img/yellow-bg-2.png" alt="sam" /></span>
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {staff.map((item, index) => {
                            return (
                                <div key={index} className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                                    <div className="teacher__item text-center grey-bg-5 transition-3 mb-30">
                                        <div className="teacher__thumb w-img fix">
                                            <img src={`../../${item.image}`} alt={item.name} />
                                        </div>
                                        <div className="teacher__content">
                                            <h3 className="teacher__title">{item.name}،</h3>
                                            <span> {item.position}</span>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section>
            <section className="why__area pt-25 grey-bg">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xxl-5 offset-xxl-1 col-xl-5 offset-xl-1 col-lg-6 col-md-8">
                            <div className="why__content pr-50 mt-40">
                                <div className="section__title-wrapper mb-30">
                                    <h2 className="section__title">
                                        <span className="yellow-bg">
                                            {ads.content}
                                            <img src="../../assets/img/yellow-bg-2.png" alt="sam" /></span>
                                    </h2>
                                </div>
                                <div className="why__btn">
                                    <a href={ads.linkto} className="link-btn">
                                        زێدەتر
                                        <i className="far fa-arrow-left" />
                                        <i className="far fa-arrow-left" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-5 col-xl-5 col-lg-6 col-md-8">
                            <div className="why__thumb">
                                <img src={`../../${ads.image}`} alt={ads.content} />
                                <img className="why-green" src="../../assets/img/why-shape-green.png" alt="sam" />
                                <img className="why-pink" src="../../assets/img/why-shape-pink.png" alt="sam" />
                                <img className="why-dot" src="../../assets/img/why-shape-dot.png" alt="sam" />
                                <img className="why-line" src="../../assets/img/why-shape-line.png" alt="sam" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="events__area pt-115 pb-120 p-relative">
                <div className="events__shape">
                    <img className="events-1-shape" src="../../assets/img/events-shape.png" alt="sam" />
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-4 offset-xxl-4">
                            <div className="section__title-wrapper mb-60 text-center">
                                <h2 className="section__title">پێزانینێن <span className="yellow-bg">گرنگ<img src="../../assets/img/yellow-bg-2.png" alt="sam" /></span></h2>
                                <p>مە {post.length} پێزانینێن گرنگ بوتە بەرهەڤکری.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {post.map((item, index) => {
                            return (
                                <div key={index} className="col-xxl-10 offset-xxl-1 col-xl-10 offset-xl-1 col-lg-10 offset-lg-1">
                                    <div className="events__item mb-10 hover__active">
                                        <div className="events__item-inner d-sm-flex align-items-center justify-content-between white-bg">
                                            <div className="events__content">
                                                <div className="events__meta">
                                                    <span>{new Date(item.created).toLocaleDateString()}</span>
                                                </div>
                                                <h3 className="events__title"><Link to={`/post/${item.id}`}>{item.title}</Link></h3>
                                            </div>
                                            <div className="events__more">
                                                <Link to={`/post/${item.id}`} className="link-btn">
                                                    زێدەتر
                                                    <i className="far fa-arrow-left" />
                                                    <i className="far fa-arrow-left" />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section>
            <section className="cta__area mb--120">
                <div className="container">
                    <div className="cta__inner blue-bg fix">
                        <div className="cta__shape">
                            <img src="../../assets/img/cta-shape.png" alt="sam" />
                        </div>
                        <div className="row align-items-center">
                            <div className="col-xxl-7 col-xl-7 col-lg-8 col-md-8">
                                <div className="cta__content">
                                    <h3 className="cta__title">بو هەر پرسەک و هەر داخازیەک</h3>
                                </div>
                            </div>
                            <div className="col-xxl-5 col-xl-5 col-lg-4 col-md-4">
                                <div className="cta__more d-md-flex justify-content-end p-relative z-index-1">
                                    <Link to="/contact" className="e-btn e-btn-white">پەیوەندیێ بکە</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}

export default Home;