/* eslint-disable */
import { useState, useEffect } from 'react';
import axios from "axios";
import { useParams } from "react-router-dom";

const Post = () => {
    const postId = useParams().id
    const [data, setData] = useState([]);
    const [attachment, setAttachment] = useState([]);
    const getData = async () => {
        const { data } = await axios.get(`/api/post/${postId}`);
        setData(data)
        const { data: attachment } = await axios.get(`/api/attachment/${postId}`);
        setAttachment(attachment)
    }

    useEffect(() => {

        getData();
    }, [postId, document.title = `زانکوبادینان| ${data.title}`])

    return (
        <main>
            <section className="event__area pt-150 pb-120 p-relative">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-8 col-xl-8 col-lg-8">
                            <div className="events__wrapper">
                                {data.cover &&
                                    <div className="events__thumb mb-35 w-img">
                                        <img src={`../../${data.cover}`} alt={data.title} />
                                    </div>}
                                <div className="events__details mb-35">
                                    <h2 className="section__title">
                                        <span className="yellow-bg">
                                            <img src="../../assets/img/yellow-bg.png" alt="sam" />
                                        </span>
                                        {data.title}
                                    </h2>
                                    <table className="table table-sm">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>ناڤونیشان</th>
                                                <th>هاوپێچ</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {attachment.map((obj, i) => {
                                                return (
                                                    <tr>
                                                        <td>{i + 1}</td>
                                                        <td>{obj.attachment_title}</td>
                                                        <td><a href={`../../${obj.attachment}`} className='text-primary' target="_blank" rel="noreferrer">داونلۆد</a></td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                    <p>{data.content}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}

export default Post;