import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import tictac from "../../tectac.mp3";

const Quiz = () => {

    const audioRef = useRef(new Audio(tictac));
    const [countDown, setCountDown] = useState(10800);
    const [showQuiz, setShowQuiz] = useState(false);
    const startQuiz = () => {
        audioRef.current.loop = true;
        audioRef.current.play();
        setShowQuiz(true);
        let timeLeft = 10800;
        const downloadTimer = setInterval(() => {
            timeLeft--;
            setCountDown(timeLeft);
            if (timeLeft <= 0) {
                clearInterval(downloadTimer);
                setShowQuiz(false);
                audioRef.current.pause();
                audioRef.current.currentTime = 0;
            }
        }, 1000);
    }

    const isImageUrl = (str) => {
        return typeof str === "string" && /\.(gif|jpe?g|tiff?|png|webp|bmp)$/i.test(str);
    };

    const { id: quizerId } = useParams();
    const [quizer, setQuizer] = useState({});
    const [quiz, setQuiz] = useState(null);
    useEffect(() => {
        const fetchData = async () => {
            const { data: quizer } = await axios.get(`/api/quizer/${quizerId}`);
            setQuizer(quizer);
            const { data: quiz } = await axios.get("/api/quiz", { params: { quizer: quizer } });
            setQuiz(quiz);
        };
        fetchData();
    }, [quizerId]);

    const [index, setIndex] = useState(0); // Initialize with 0 for internal use
    const [correctCount, setCorrectCount] = useState(0);
    const [incorrectCount, setIncorrectCount] = useState(0);
    const [isQuizCompleted, setIsQuizCompleted] = useState(false);

    const handleAnswerClick = (selectedOption) => {
        if (isQuizCompleted) {
            axios.put(`/api/quizer/${quizerId}`, { point: correctCount * 2 });
            setShowQuiz(false);
            toast.success("Quiz is completed");
            return;
        }
        const quizLength = quiz.length;
        const correctOption = quiz[index].answers[0].correct;
        if (selectedOption === correctOption) {
            setCorrectCount(correctCount + 1);
        } else {
            setIncorrectCount(incorrectCount + 1);
        }

        if (index + 1 < quizLength && index + 1 < 50) {
            setIndex(index + 1);
        } else {
            setIsQuizCompleted(true);
            setShowQuiz(false);
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
        }
    };

    useEffect(() => {
        axios.put(`/api/quizer/${quizerId}`, { point: correctCount * 2 });
    }, [correctCount]);


    return (
        <>

            <section className={`why__area pt-25 grey-bg ${showQuiz ? "d-none" : ""}`}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xxl-5 offset-xxl-1 col-xl-5 offset-xl-1 col-lg-6 col-md-8">
                            <div className="why__content pr-50 mt-40">
                                <div className="section__title-wrapper mb-30">
                                    <h2 className="section__title mb-2">قوتابی: {quizer.full_name}</h2>
                                    <h2 className="section__title">بابەت: {quizer.subject}</h2>
                                    <h2 className="section__title">پۆلا: {quizer.grade} {quizer.edu_str}</h2>
                                    {correctCount * 2 !== 0 && (
                                        <>
                                            <div className="student__info">
                                                <h2 className="section__title">ژمارا پرسیاران: {correctCount + incorrectCount}</h2>
                                                <h2 className="section__title">بەرسڤا درست: {correctCount}/ 50</h2>
                                                <h2 className="section__title">بەرسڤا خەلەت: {incorrectCount}/ 50</h2>
                                                <h2 className="section__title">نمرە: {correctCount * 2}/100</h2>
                                                <h2 className="section__title">
                                                    ئەنجام: {correctCount * 2 >= 50 ? <span className="text-success">دەرچوو</span> : <span className="text-danger">دەرنەچوو !</span>}
                                                </h2>
                                            </div>

                                        </>
                                    )}
                                    {correctCount * 2 === 0 && (
                                        <div className="why__btn">
                                            <button type="button" className="e-btn e-btn-7 w-50" onClick={startQuiz}>
                                                دەستپێکرن <i className="far fa-play"></i>
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-5 col-xl-5 col-lg-6 col-md-8">
                            <div className="why__thumb">
                                <img src={`../../why.png`} alt="ready" />
                                <img className="why-green" src="../../assets/img/why-shape-green.png" alt="sam" />
                                <img className="why-pink" src="../../assets/img/why-shape-pink.png" alt="sam" />
                                <img className="why-dot" src="../../assets/img/why-shape-dot.png" alt="sam" />
                                <img className="why-line" src="../../assets/img/why-shape-line.png" alt="sam" />
                            </div>
                        </div>
                        {correctCount * 2 !== 0 && (
                            <div className="mx-auto col-md-12 col-lg-7">
                                <div className="somo_summary">
                                    <h2 className="bg-warning py-2 px-3">پرسیار و بەرسڤێن درست (سۆمۆ)</h2>
                                    <div className="row">
                                        {quiz && quiz.map((q, i) => (
                                            <div className="col-12 mb-3" key={i}>
                                                <h2 className="section__title">پرسیارا ({i + 1})</h2>
                                                {isImageUrl(q.question) ? <img src={`../../${q.question}`} alt="question" /> : <p className="custom-font">{q.question}</p>}
                                                <h2 className="section__title">بەرسڤا</h2>
                                                <ul>
                                                    {['a', 'b', 'c', 'd'].map((opt) => (
                                                        <li key={opt} className={q.answers[0].correct === opt ? "text-success" : "text-danger"}>
                                                            {opt.toUpperCase()}: {isImageUrl(q.answers[0][`option_${opt}`]) ? <img src={`../../${q.answers[0][`option_${opt}`]}`} alt="answer option" /> : q.answers[0][`option_${opt}`]}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </section>

            <section className={`event__area pt-150 mb-5 p-relative ${!showQuiz ? "d-none" : ""}`}>
                {quiz && (
                    <>
                        <div className="container">
                            <div className="mx-auto col-md-12 col-lg-7">
                                <form className="show-section">
                                    <section className="steps-inner pop-slide">
                                        <div className="wrapper">
                                            <div className="form-heading text-center bg-light">
                                                <h4>
                                                    <strong>(پرسیارا {index + 1})</strong>
                                                </h4>
                                                {quiz && (
                                                    isImageUrl(quiz[index].question) ? <img src={`../../${quiz[index].question}`} alt="question" /> : <p className="custom-font">{quiz[index].question}</p>
                                                )}
                                                <div className="row mt-3">
                                                    <div className="col-6">
                                                        <span className="badge bg-success">بەرسڤا درست: {correctCount}/ 50</span>
                                                    </div>
                                                    <div className="col-6">
                                                        <span className="badge bg-danger">بەرسڤا خەلەت: {incorrectCount}/ 50</span>
                                                    </div>
                                                </div>
                                            </div>
                                            {['a', 'b', 'c', 'd'].map((opt) => (
                                                <div className="form-inner mb-4" key={opt}>
                                                    <label className="form-input answer-list mb-2 input opt">
                                                        <span type="button" className="badge rounded-pill bg-dark">{opt.toUpperCase()}</span>
                                                        <input type="radio" name="quiz" value={quiz[index].answers[0][`option_${opt}`]} onClick={() => handleAnswerClick(opt)} />
                                                        {isImageUrl(quiz[index].answers[0][`option_${opt}`]) ? <img src={`../../${quiz[index].answers[0][`option_${opt}`]}`} alt="answer option" /> : <span type="button" className="custom-font">{quiz[index].answers[0][`option_${opt}`]}</span>}
                                                    </label>
                                                </div>
                                            ))}
                                            <div className="step-heading mb-5">
                                                <p>تێبینی: {quiz && quiz[index].note}</p>
                                            </div>
                                        </div>
                                    </section>
                                </form>
                            </div>
                        </div>
                        <div className="sm-sticky">
                            <div className="timer">
                                <div className="countdown">
                                    <span id="countdown-timer" className="time">{countDown}</span>
                                    <span className="time-ex">(3 دەمژمێر)</span>
                                </div>
                                <div className="timer-heading">
                                    بابەت<br /> {quiz && quiz[index].subject}
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </section>

            <section className="cta__area mb--120 mt-5">
                <div className="container">
                    <div className="cta__inner black-bg fix">
                        <div className="cta__shape">
                            <img src="../../assets/img/cta-shape.png" alt="sam" />
                        </div>
                        <div className="row align-items-center">
                            <div className="col-xxl-7 col-xl-7 col-lg-8 col-md-8">
                                <div className="cta__content">
                                    <h3 className="cta__title">بۆ هەر پێزانینەک پەیجێ مە فۆلۆو بکەن</h3>
                                </div>
                            </div>
                            <div className="col-xxl-5 col-xl-5 col-lg-4 col-md-4">
                                <div className="cta__more d-md-flex justify-content-end p-relative z-index-1">
                                    <div className="footer__social">
                                        <ul>
                                            <li>
                                                <a href="https://www.snapchat.com/add/zankobadinan" target="_blank" rel="noreferrer">
                                                    <div>
                                                        <i className="fab fa-snapchat-square text-warning fa-3x" />
                                                    </div>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://www.instagram.com/zankobadinan/" target="_blank" rel="noreferrer">
                                                    <div>
                                                        <i className="fab fa-instagram text-danger fa-3x" />
                                                    </div>
                                                </a>
                                            </li>
                                            <li>
                                                <Link to="/start" >
                                                    <div>
                                                        <i className="far fa-undo-alt text-primary fa-3x" />
                                                    </div>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >

        </>
    );
};

export default Quiz;
