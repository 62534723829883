import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import commaNumber from "comma-number";

const Courses = () => {
    const [course, setCourse] = useState([]);
    const title = document.title = "زانکۆبادینان، کۆرس";
    const getData = async () => {
        const { data: course } = await axios.get('/api/course');
        setCourse(course)
    }

    useEffect(() => {
        getData();
    }, [title])
    const colors = ["sky-blue", "green", "orange", "pink"]

    return (
        <main>
            <section className="course__area pt-150 pb-120 p-relative grey-bg">
                <div className="container">
                    <div className="row align-items-end">
                        <div className="col-xxl-5 col-xl-6 col-lg-6">
                            <div className="section__title-wrapper mb-60">
                                <h2 className="section__title">نوێترین <span className="yellow-bg">کۆرس<img src="../../assets/img/yellow-bg.png" alt="sam" /></span></h2>
                            </div>
                        </div>
                    </div>
                    <div className="row grid">
                        {course.map((item, index) => {
                            return (
                                <div key={index} className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 grid-item cat1 cat2 cat4">
                                    <div className="course__item white-bg mb-30 fix">
                                        <div className="course__thumb w-img p-relative fix">
                                            <Link to={`/submit/${item.id}`}>
                                                <img src={`../../${item.cover}`} alt={item.subject} />
                                            </Link>
                                            <div className="course__tag">
                                                <Link to={`/submit/${item.id}`} className={colors[index % colors.length]}>{item.subject}</Link>
                                            </div>
                                        </div>
                                        <div className="course__content">
                                            <div className="course__meta d-flex align-items-center justify-content-between">
                                                <div className="course__rating">
                                                    <span><i className="far fa-school" />{item.school}</span>
                                                </div>
                                            </div>
                                            <h3 className="course__title"><Link to={`/submit/${item.id}`}>{item.description}</Link></h3>
                                            <div className="course__teacher d-flex align-items-center">
                                                <div className="course__teacher-thumb mr-15">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" /><circle cx={12} cy={7} r={4} /></svg>
                                                </div>
                                                <h6><Link to={`/submit/${item.id}`}>{item.teacher}</Link></h6>
                                            </div>
                                        </div>
                                        <div className="course__more d-flex justify-content-between align-items-center">
                                            <div className="course__status">
                                                <span className={colors[index % colors.length]}>{commaNumber(item.price)} د.ع</span>
                                            </div>
                                            <div className="course__btn">
                                                <Link to={`/submit/${item.id}`} className="link-btn">
                                                    پشکداریکرن
                                                    <i className="far fa-arrow-left" />
                                                    <i className="far fa-arrow-left" />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section>

        </main>
    );
}

export default Courses;