import React, { useState, useEffect, useRef, useReducer } from "react";
import axios from "axios";
import { useParams } from 'react-router-dom';
import { toast } from "react-toastify";
import $ from "jquery";
import tictac from '../tectac.mp3'

const playAudio = () => {
    const audio = new Audio(tictac);
    audio.play();
};


const quizReducer = (state, action) => {
    switch (action.type) {
        case 'NEXT_QUESTION':
            return { ...state, currentQuestionIndex: state.currentQuestionIndex + 1 };
        default:
            return state;
    }
};

const Quiz = () => {
    const { id: quizerId } = useParams();
    const [hasQuizStarted, setHasQuizStarted] = useState(false);

    const startQuiz = () => {
        if (allQuestions.length > 0) {
            setHasQuizStarted(true);
            startCountdown();
            setShowQuiz(true);
        } else {
            toast.warning('چ تاقیکرن بەردەستنین !')
        }
    };

    const isCorrectRef = useRef(false);

    const updateIsCorrect = (a, b, c) => {
        isCorrectRef.current = { a, b, c };
    };

    const startCountdown = () => {
        clearInterval(countdownInterval.current);
        resetTimer();
        playAudio();
        countdownInterval.current = setInterval(() => {
            setTimer(prevTimer => {
                if (prevTimer === 1) {
                    if (isCorrectRef.current.a) {
                        $(`.input-${isCorrectRef.current.b}`).addClass('bg-success')
                    } else {
                        $(`.input-${isCorrectRef.current.b}`).addClass('bg-danger')
                        $(`.input-${isCorrectRef.current.c}`).addClass('bg-success')
                    }
                }
                if (prevTimer === 0) {
                    axios.put('/api/visitor').then();
                    if (currentQuestionIndex + 1 < allQuestions.length) {
                        dispatch({ type: 'NEXT_QUESTION' });
                        $(`.answer-list`).removeClass('bg-success')
                        $(`.answer-list`).removeClass('bg-danger')
                    } else {
                        setHasQuizStarted(false); // End the quiz
                    }
                    return 20; // Reset timer for next question
                }
                return prevTimer - 1;
            });
        }, 1000);
    };

    const resetTimer = () => setTimer(20);

    const shuffleArray = array => {
        return [...array].sort(() => Math.random() - 0.5);
    };

    const [showQuiz, setShowQuiz] = useState(false);
    const [quizer, setQuizer] = useState({});
    const [allQuestions, setAllQuestions] = useState([]);
    const [{ currentQuestionIndex }, dispatch] = useReducer(quizReducer, { currentQuestionIndex: 0 });
    const [question, setQuestion] = useState({});
    const [answer, setAnswer] = useState([]);
    const [activeInput, setActiveInput] = useState('');
    const [currentQuestionNumber, setCurrentQuestionNumber] = useState(1);
    const [timer, setTimer] = useState(20);
    const countdownInterval = useRef(null);

    // Fetches quiz data
    const fetchQuizData = async () => {
        try {
            const { data: quizerData } = await axios.get(`/api/quizer/${quizerId}`);
            setQuizer(quizerData);
            const { data: questions } = await axios.get(`/api/question`, {
                params: { subject_id: quizerData.subject_id, grade: quizerData.grade, edu_lang: quizerData.edu_lang, season: quizerData.season, unit: quizerData.unit, question_type: quizerData.question_type }
            });
            const shuffledQuestions = shuffleArray(questions);
            setAllQuestions(shuffledQuestions);
            if (shuffledQuestions.length > 0) {
                setQuestion(shuffledQuestions[0]);
                fetchAnswer(shuffledQuestions[0].id);
            }
        } catch (error) {
            console.error("Error fetching quiz data:", error);
        }
    };

    

    // Fetches answers for a given question
    const fetchAnswer = async (questionId) => {
        try {
            const { data: newAnswer } = await axios.get(`/api/answer/${questionId}`);
            setAnswer(newAnswer);
        } catch (error) {
            console.error("Error fetching answers:", error);
        }
    };

    useEffect(() => {
        fetchQuizData();
        return () => clearInterval(countdownInterval.current);
    }, [quizerId]);

    const calculateResults = (answerQuiz) => {
        const results = answerQuiz.reduce((acc, item) => {
            if (item.selected === item.answer.correct) {
                acc.correctCount += 1;
                acc.totalScore += 2;
            } else {
                acc.incorrectCount += 1;
            }
            return acc;
        }, { correctCount: 0, incorrectCount: 0, totalScore: 0 });

        return results;
    };

    const [answerQuiz, setAnswerQuiz] = useState([]);
    const [correctCount, setCorrectCount] = useState(0);
    const [incorrectCount, setIncorrectCount] = useState(0);
    const [totalScore, setTotalScore] = useState(0);

    const submitAnswer = async () => {
        // Calculate results after quiz completion
        const results = calculateResults(answerQuiz);
        setCorrectCount(results.correctCount);
        setIncorrectCount(results.incorrectCount);
        setTotalScore(results.totalScore);
        // Implement your submission logic here using the answerQuiz state
        clearInterval(countdownInterval.current); // Stop the countdown
        setShowQuiz(false); // Optionally, hide the quiz area
        setHasQuizStarted(false); // Reset for next quiz

        axios.put(`/api/quizer/${quizerId}`, { point: results.totalScore }).then(() => {
            toast.success('تاقیکرن ب دماهیک هات');
        })
    };

    useEffect(() => {
        if (!hasQuizStarted && answerQuiz.length > 0) {
            submitAnswer();
        }
    }, [hasQuizStarted, answerQuiz]);


    const handleInputChange = inputName => setActiveInput(inputName);
    useEffect(() => {
        if (currentQuestionIndex < allQuestions.length) {
            const nextQuestion = allQuestions[currentQuestionIndex];
            setQuestion(nextQuestion);
            setCurrentQuestionNumber(currentQuestionIndex + 1);
            fetchAnswer(nextQuestion.id);
            setActiveInput('');
        } else {
            // End the quiz
            setHasQuizStarted(false);
        }
    }, [currentQuestionIndex, allQuestions]);

    function isImageUrl(str) {
        if (typeof str !== 'string') return false;
        return (/\.(gif|jpe?g|tiff?|png|webp|bmp)$/i).test(str);
    }


    return (
        <>
            <section className={`why__area pt-25 grey-bg ${showQuiz ? 'd-none' : ''}`}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xxl-5 offset-xxl-1 col-xl-5 offset-xl-1 col-lg-6 col-md-8">
                            <div className="why__content pr-50 mt-40">
                                <div className="section__title-wrapper mb-30">
                                    <h2 className="section__title mb-2">
                                        قوتابی:  {quizer.full_name}
                                    </h2>
                                    <h2 className="section__title">
                                        بابەت: {quizer.subject}
                                    </h2>
                                    <h2 className="section__title">
                                        پۆلا: {quizer.grade} {quizer.edu_str}
                                    </h2>
                                    {totalScore !== 0 &&
                                        <>
                                            <h2 className="section__title">
                                                نمرە: {totalScore}/100
                                            </h2>
                                            <h2 className="section__title">
                                                وەڵامی ڕاست: {correctCount}/ 50
                                            </h2>
                                            <h2 className="section__title">
                                                وەڵامی خەلەت: {incorrectCount}/50
                                            </h2>
                                        </>
                                    }
                                    <span className="yellow-bg">
                                        <img src="../../assets/img/yellow-bg.png" alt="sam" />
                                    </span>
                                </div>
                                {totalScore === 0 &&
                                    <div className="why__btn">
                                        <button type="button" className="e-btn e-btn-7 w-50" onClick={startQuiz}>
                                            دەستپێکرن <i class="far fa-play"></i>
                                        </button>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="col-xxl-5 col-xl-5 col-lg-6 col-md-8">
                            <div className="why__thumb">
                                <img src={`../../why.png`} alt='ready' />
                                <img className="why-green" src="../../assets/img/why-shape-green.png" alt="sam" />
                                <img className="why-pink" src="../../assets/img/why-shape-pink.png" alt="sam" />
                                <img className="why-dot" src="../../assets/img/why-shape-dot.png" alt="sam" />
                                <img className="why-line" src="../../assets/img/why-shape-line.png" alt="sam" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className={`event__area pt-150 mb-5 p-relative ${!showQuiz ? 'd-none' : ''}`}>
                <div className="container">
                    <div className="mx-auto col-md-12 col-lg-7">
                        <form className="show-section">
                            <section className="steps-inner pop-slide">
                                <div className="wrapper">
                                    <div className="form-heading text-center bg-light">
                                        <br />
                                        <h4><strong>(پرسیارا {currentQuestionNumber})</strong></h4>
                                        {isImageUrl(allQuestions[currentQuestionIndex]?.question) ?
                                            <img src={`../../${allQuestions[currentQuestionIndex]?.question}`} alt="Question" /> :
                                            allQuestions[currentQuestionIndex]?.question
                                        }
                                    </div>
                                    <div className="step-bar mb-4">
                                        <div className="step-bar-inner">
                                            <div className="step-bar-move step-move" style={{ width: `${(currentQuestionNumber / 50) * 100}%` }}><point className="pointer" >{currentQuestionNumber}/50</point></div>
                                        </div>
                                    </div>
                                    <div className="form-inner mb-4">
                                        {['a', 'b', 'c', 'd'].map(option => (
                                            <label key={option} className={`form-input answer-list mb-2 input-${option} ${activeInput === option ? 'active-input' : ''}`}>
                                                <span className="badge rounded-pill bg-dark">{option.toUpperCase()}</span>
                                                <input type="radio" name="quiz" value={answer[`option_${option}`]} checked={activeInput === option} onChange={() => {
                                                    if (option === answer.correct) {
                                                        updateIsCorrect(true, option, answer.correct)
                                                    } else {
                                                        updateIsCorrect(false, option, answer.correct)
                                                    }
                                                    handleInputChange(option);
                                                    setAnswerQuiz(prevState => [
                                                        ...prevState,
                                                        {
                                                            answer: answer,
                                                            selected: option
                                                        }
                                                    ]);
                                                }} />
                                                {isImageUrl(answer[`option_${option}`]) ?
                                                    <img src={`../../${answer[`option_${option}`]}`} alt={`Option ${option}`} /> : answer[`option_${option}`]
                                                }
                                            </label>
                                        ))}
                                    </div>
                                    <div className="step-heading mb-5">
                                        <p>تێبینی: {question.note}</p>
                                    </div>
                                </div>
                            </section>
                        </form>
                    </div>
                </div>
                <div className="sm-sticky">
                    <div className="timer">
                        <div className="countdown">
                            <span id="countdown-timer" className="time">{timer}</span>
                            <span className="time-ex">چرکە</span>
                        </div>
                        <div className="timer-heading"> بابەت<br /> {question.subject}</div>
                    </div>
                </div>
            </section>
            <section className="cta__area mb--120 mt-5">
                <div className="container">
                    <div className="cta__inner black-bg fix">
                        <div className="cta__shape">
                            <img src="../../assets/img/cta-shape.png" alt="sam" />
                        </div>
                        <div className="row align-items-center">
                            <div className="col-xxl-7 col-xl-7 col-lg-8 col-md-8">
                                <div className="cta__content">
                                    <h3 className="cta__title">بۆ هەر پێزانینەک پەیجێ مە فۆلۆو بکەن</h3>
                                </div>
                            </div>
                            <div className="col-xxl-5 col-xl-5 col-lg-4 col-md-4">
                                <div className="cta__more d-md-flex justify-content-end p-relative z-index-1">
                                    <div className="footer__social">
                                        <ul>
                                            <li>
                                                <a href="https://www.snapchat.com/add/zankobadinan" target="_blank" rel="noreferrer">
                                                    <div>
                                                        <i className="fab fa-snapchat-square text-warning fa-3x" />
                                                    </div>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://www.instagram.com/zankobadinan/" target="_blank" rel="noreferrer">
                                                    <div>
                                                        <i className="fab fa-instagram text-danger fa-3x" />
                                                    </div>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Quiz;