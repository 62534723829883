import { useEffect, useState } from "react";
import axios from "axios";

const Contact = () => {
    const title = document.title = "زانکۆبادینان، پەیوەندیێ ب مە بکە";
    const [data, setDats] = useState([]);
    const [social, setSocial] = useState([]);
    const getData = async () => {
        const { data } = await axios.get('/api/general');
        setDats(data)
        const { data: social } = await axios.get('/api/social');
        setSocial(social)
    }

    useEffect(() => {
        getData();
    }, [title])
    return (
        <main>
            <section className="contact__area pt-140 pb-120">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-4 offset-xxl-1 col-xl-4 offset-xl-1 col-lg-5 offset-lg-1">
                            <div className="contact__info white-bg p-relative z-index-1">
                                <div className="contact__shape">
                                    <img className="contact-shape-1" src="../../assets/img/contact-shape-1.png" alt="sam" />
                                    <img className="contact-shape-2" src="../../assets/img/contact-shape-2.png" alt="sam" />
                                    <img className="contact-shape-3" src="../../assets/img/contact-shape-3.png" alt="sam" />
                                </div>
                                <div className="contact__info-inner white-bg">
                                    <ul>
                                        <li>
                                            <div className="contact__info-item d-flex align-items-start mb-35">
                                                <div className="contact__info-icon mr-15">
                                                    <svg className="map" viewBox="0 0 24 24">
                                                        <path className="st0" d="M21,10c0,7-9,13-9,13s-9-6-9-13c0-5,4-9,9-9S21,5,21,10z" />
                                                        <circle className="st0" cx={12} cy={10} r={3} />
                                                    </svg>
                                                </div>
                                                <div className="contact__info-text">
                                                    <h4>ناڤ ونیشان</h4>
                                                    <p>{data.address} </p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="contact__info-item d-flex align-items-start mb-35">
                                                <div className="contact__info-icon mr-15">
                                                    <svg className="call" viewBox="0 0 24 24">
                                                        <path className="st0" d="M22,16.9v3c0,1.1-0.9,2-2,2c-0.1,0-0.1,0-0.2,0c-3.1-0.3-6-1.4-8.6-3.1c-2.4-1.5-4.5-3.6-6-6  c-1.7-2.6-2.7-5.6-3.1-8.7C2,3.1,2.8,2.1,3.9,2C4,2,4.1,2,4.1,2h3c1,0,1.9,0.7,2,1.7c0.1,1,0.4,1.9,0.7,2.8c0.3,0.7,0.1,1.6-0.4,2.1  L8.1,9.9c1.4,2.5,3.5,4.6,6,6l1.3-1.3c0.6-0.5,1.4-0.7,2.1-0.4c0.9,0.3,1.8,0.6,2.8,0.7C21.3,15,22,15.9,22,16.9z" />
                                                    </svg>
                                                </div>
                                                <div className="contact__info-text">
                                                    <h4>ژ.مۆبایل</h4>
                                                    <p><a href={`tel:+(964)${data.phone_no}`}>{data.phone_no}</a></p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="contact__info-item d-flex align-items-start mb-35">
                                                <div className="contact__info-icon mr-15">
                                                    <svg className="mail" viewBox="0 0 24 24">
                                                        <path className="st0" d="M4,4h16c1.1,0,2,0.9,2,2v12c0,1.1-0.9,2-2,2H4c-1.1,0-2-0.9-2-2V6C2,4.9,2.9,4,4,4z" />
                                                        <polyline className="st0" points="22,6 12,13 2,6 " />
                                                    </svg>
                                                </div>
                                                <div className="contact__info-text">
                                                    <h4>ئیمەیل</h4>
                                                    <p><a href={`mailto:${data.email}`}>{data.email}</a></p>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                    <div className="contact__social pl-30">
                                        <h4>سوشیال لینک</h4>
                                        <ul>
                                            {social.map((item, index) => {
                                                return (
                                                    <li key={index}><a href={item.social_link} target="_blank" rel="noreferrer"><div dangerouslySetInnerHTML={{ __html: item.social_icon }} /></a></li>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-7 col-xl-7 col-lg-6 pt-75">
                            <div className="contact__item text-center mb-30 transition-3 white-bg">
                                <div className="contact__icon d-flex justify-content-center align-items-end">
                                    <svg viewBox="0 0 24 24">
                                        <path className="st0" d="M21,10.8c0,1.3-0.3,2.6-0.9,3.8c-1.4,2.9-4.4,4.7-7.6,4.7c-1.3,0-2.6-0.3-3.8-0.9L3,20.3l1.9-5.7  C4.3,13.4,4,12.1,4,10.8c0-3.2,1.8-6.2,4.7-7.6c1.2-0.6,2.5-0.9,3.8-0.9H13c4.3,0.2,7.8,3.7,8,8V10.8z" />
                                        <g>
                                            <circle className="st1" cx="9.3" cy="10.5" r="0.5" />
                                            <circle className="st1" cx="12.5" cy="10.5" r="0.5" />
                                            <circle className="st1" cx="15.7" cy="10.5" r="0.5" />
                                        </g>
                                    </svg>
                                </div>
                                <div className="contact__content">
                                    <h3 className="contact__title">ئۆنلاین</h3>
                                    <p>ب ڕێکا ئۆنلاین گەل ستافێ زانکو بادینان باخڤە</p>
                                    <a href="https://www.facebook.com/zanko.badinan" target="_blank" rel="noreferrer" className="e-btn e-btn-border">بەردەوامبوون</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}

export default Contact;