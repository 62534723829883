/* eslint-disable */
import { useState, useEffect } from 'react';
import axios from "axios";
import { useParams } from "react-router-dom";
import commaNumber from "comma-number";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Submit = () => {
    const navigate = useNavigate();
    const courseId = useParams().id
    const [data, setData] = useState([]);
    const getData = async () => {
        const { data } = await axios.get(`/api/course/${courseId}`);
        setData(data)
    }

    useEffect(() => {
        getData();
    }, [courseId, document.title = `زانکوبادینان| پێشکێشکرنا فورمی بو کۆرسێ ${data.subject}`])

    return (
        <main>
            <section className="event__area pt-150 pb-120 p-relative">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-7 col-xl-7 col-lg-7">
                            <div className="course__wrapper">
                                <div className="page__title-content mb-25">
                                    <h2 className="section__title">پێشکێشکرنا فورما پشکداریکرنێ ل <span className="yellow-bg"> کۆرسا {data.subject}<img src="../../assets/img/yellow-bg.png" alt="sam" /></span></h2>
                                </div>
                                <div className="course__tab-content mb-95">
                                    <form onSubmit={(e) => {
                                        e.preventDefault();
                                        const formData = new FormData(e.target);
                                        const data = Object.fromEntries(formData);
                                        data.course_id = parseInt(courseId)
                                        axios.post(`/api/submit`, data).then(() => {
                                            toast.success('داخازیاتە هاتە پێشکێشکرن !');
                                            navigate('/')
                                        })
                                    }} >
                                        <div className="form-group mb-5">
                                            <label htmlFor="full_name" className='mb-1'>ناڤێ سیانی</label>
                                            <input type="text" className="form-control" name="full_name" placeholder='ناڤێ سیانی' required />
                                        </div>
                                        <div className="form-group mb-5">
                                            <label htmlFor="phone_no" className='mb-1'>ژ.مۆبایل</label>
                                            <input type="tel" className="form-control" name="phone_no" placeholder='ژ.مۆبایل' required />
                                        </div>
                                        <div className="form-group mb-5">
                                            <label htmlFor="department" className='mb-1'>پشک</label>
                                            <select className="form-control" name="department" required>
                                                <option value>پشکا خو دیاربکە ...</option>
                                                <option value="زانستی">زانستی</option>
                                                <option value="وێژەیی">وێژەیی</option>
                                                <option value="زانکو/پەیمانگە">زانکو/پەیمانگە</option>
                                            </select>
                                        </div>
                                        <div className="form-group mb-5 mt-40">
                                            <button type="submit" className="e-btn e-btn-7 w-100">پێشکێشکرن <i class="far fa-check"></i></button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-5 col-xl-5 col-lg-5">
                            <div className="course__sidebar pl-70 p-relative">
                                <div className="course__shape">
                                    <img className="course-dot" src="../../assets/img/course-dot.png" alt="sam" />
                                </div>
                                <div className="course__sidebar-widget-2 white-bg mb-20">
                                    <div className="course__video">
                                        <div className="course__video-thumb w-img mb-25">
                                            <img src={`../../${data.cover}`} alt={data.subject} />
                                        </div>
                                        <div className="course__video-meta mb-10 d-flex align-items-center justify-content-between">
                                            <div className="course__video-price">
                                                <h5>{commaNumber(data.price)} د.ع</h5>
                                                <p>{data.description}</p>
                                            </div>
                                        </div>
                                        <div className="course__video-content mb-35">
                                            <ul>
                                                <li className="d-flex align-items-center">
                                                    <div className="course__video-icon">
                                                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 24 24" style={{ enableBackground: 'new 0 0 24 24' }} xmlSpace="preserve">
                                                            <path className="st0" d="M4,19.5C4,18.1,5.1,17,6.5,17H20" />
                                                            <path className="st0" d="M6.5,2H20v20H6.5C5.1,22,4,20.9,4,19.5v-15C4,3.1,5.1,2,6.5,2z" />
                                                        </svg>
                                                    </div>
                                                    <div className="course__video-info">
                                                        <h5>{data.subject}</h5>
                                                    </div>
                                                </li>
                                                <li className="d-flex align-items-center">
                                                    <div className="course__video-icon">
                                                        <svg>
                                                            <path className="st0" d="M13.3,14v-1.3c0-1.5-1.2-2.7-2.7-2.7H5.3c-1.5,0-2.7,1.2-2.7,2.7V14" />
                                                            <circle className="st0" cx={8} cy="4.7" r="2.7" />
                                                        </svg>
                                                    </div>
                                                    <div className="course__video-info">
                                                        <h5>{data.teacher}</h5>
                                                    </div>
                                                </li>
                                                <li className="d-flex align-items-center">
                                                    <div className="course__video-icon">
                                                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 16 16" style={{ enableBackground: 'new 0 0 16 16' }} xmlSpace="preserve">
                                                            <path className="st0" d="M2,6l6-4.7L14,6v7.3c0,0.7-0.6,1.3-1.3,1.3H3.3c-0.7,0-1.3-0.6-1.3-1.3V6z" />
                                                            <polyline className="st0" points="6,14.7 6,8 10,8 10,14.7 " />
                                                        </svg>
                                                    </div>
                                                    <div className="course__video-info">
                                                        <h5>{data.school}</h5>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}

export default Submit;