import { useEffect, useState } from "react";
import $ from 'jquery';
import { Link } from "react-router-dom";
import axios from "axios";


const Header = () => {
    function extScript() {
        var windowOn = $(window);
        windowOn.on('load', function () {
            $("#loading").fadeOut(500);
        });

        window.$('#mobile-menu').meanmenu({
            meanMenuContainer: '.mobile-menu',
            meanScreenWidth: "1199",
            meanExpand: ['<i class="fal fa-plus"></i>'],
        });

        $("#sidebar-toggle").on("click", function () {
            $(".sidebar__area").addClass("sidebar-opened");
            $(".body-overlay").addClass("opened");
        });
        $(".sidebar__close-btn").on("click", function () {
            $(".sidebar__area").removeClass("sidebar-opened");
            $(".body-overlay").removeClass("opened");
        });

        windowOn.on('scroll', function () {
            var scroll = $(window).scrollTop();
            if (scroll < 100) {
                $("#header-sticky").removeClass("sticky");
            } else {
                $("#header-sticky").addClass("sticky");
            }
        });

        $("[data-width]").each(function () {
            $(this).css("width", $(this).attr("data-width"));
        });

        $('#showlogin').on('click', function () {
            $('#checkout-login').slideToggle(900);
        });

        $('.hover__active').on('mouseenter', function () {
            $(this).addClass('active').parent().siblings().find('.hover__active').removeClass('active');
        });
    }

    const [cat, setCat] = useState([]);
    const [social, setSocial] = useState([]);
    const getData = async () => {
        const { data: cat } = await axios.get('/api/cat');
        setCat(cat)
        const { data: social } = await axios.get('/api/social');
        setSocial(<a href={social[2].social_link} target="_blank" rel="noreferrer"><span style={{ position: 'relative', top: '7px', fontSize: 35, color: '#FFC107' }} dangerouslySetInnerHTML={{ __html: social[2].social_icon }} /></a>)

    }

    useEffect(() => {
        getData();
        extScript();
    }, [window.scrollTo({ top: 0, behavior: 'smooth' })]);

    return (
        <header>
            <div id="header-sticky" className="header__area header__transparent header__padding">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-2 col-sm-4 col-6">
                            <div className="header__left d-flex">
                                <div className="logo">
                                    <Link to="/" className="section__title">زانکۆ&nbsp;
                                        <span className="yellow-bg">بادینان<img src="../../assets/img/yellow-bg.png" alt="sam" /></span>
                                    </Link>

                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-9 col-xl-9 col-lg-8 col-md-10 col-sm-8 col-6">
                            <div className="header__right d-flex justify-content-end align-items-center">
                                <div className="main-menu">
                                    <nav id="mobile-menu">
                                        <ul>
                                            <li><Link to="/">دەستپێک</Link></li>
                                            <li><Link to="/posts">پێزانین</Link></li>
                                            <li><Link to="/courses">کۆرس</Link></li>
                                            <li><Link to="/submition">تۆماربوون</Link></li>
                                            <li><Link to="/submition-result">زانکۆلاین</Link></li>
                                            <li className="has-dropdown">
                                                <Link to="/posts">پشک</Link>
                                                <ul className="submenu">
                                                    {cat.map((item, index) => {
                                                        return (
                                                            <li key={index}><Link to={`/cat/${item.id}`}>{item.name}</Link></li>
                                                        )
                                                    })}
                                                </ul>
                                            </li>
                                            <li><Link to="/start">هەلسەنگاندن</Link></li>
                                        </ul>
                                    </nav>
                                </div>
                                <div className="header__btn ml-20 d-none d-sm-block">
                                    <Link to="/contact" className="e-btn">پەیوەندی</Link>
                                </div>
                                <div className="sidebar__menu d-xl-none">
                                    <div className="sidebar-toggle-btn ml-30" id="sidebar-toggle">
                                        <span className="line" />
                                        <span className="line" />
                                        <span className="line" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>

    );
}

export default Header;