import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';

const SubmitQuiz = () => {
    const navigate = useNavigate();
    const [quizerData, setData] = useState({});
    const [grade, setGrade] = useState([10, 11, 12]);
    const [subject, setSubject] = useState([]);
    useEffect(() => {
        axios.get(`/api/subject/1`).then((res) => {
            setSubject(res.data);
        })
    }, [])

    const [season, setSeason] = useState([]);

    return (
        <section className="categories-area mt-100 ptb-100" >
            <div className="container quizer" id="stage1">
                <div className="text-right">
                    <h2>پێزانینێت خو بنڤیسە</h2>
                </div>
                <div className="row">
                    <div className="col-lg-12 col-md-12">
                        <div className="course__tab-content mb-95">
                            <form onSubmit={(e) => {
                                e.preventDefault();
                                const formData = new FormData(e.target);
                                const data = Object.fromEntries(formData);
                                setData(data);
                                axios.get(`api/filter`, { params: data }).then((res) => {
                                    setSeason(res.data);
                                })
                                document.getElementById('stage1').style.display = 'none';
                                document.getElementById('stage2').style.display = 'block';
                            }} >
                                <div className="row">
                                    <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                            <label className='mb-1'>ناڤێ سیانی</label>
                                            <input type="text" className="form-control" name="full_name" placeholder='ناڤێ سیانی' required />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                            <label className='mb-1'>ناڤێ قوتابخانێ</label>
                                            <input type="text" className="form-control" name="school" required />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                            <label className='mb-1'>دیالێکت</label>
                                            <select className="form-control" name="edu_lang" required>
                                                <option value="بادینی">بادینی</option>
                                                <option value="سۆرانی">سۆرانی</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                            <label className='mb-1'>ئاستێ خاندنێ</label>
                                            <select className="form-control" name="edu_type" required onChange={((e) => {
                                                if (e.target.value === '3') {
                                                    setGrade([9]);
                                                } else {
                                                    setGrade([10, 11, 12]);
                                                }
                                                axios.get(`/api/subject/${e.target.value}`).then((res) => {
                                                    setSubject(res.data);
                                                })
                                            })}>
                                                <option value="1">زانستی</option>
                                                <option value="2">وێژەیی</option>
                                                <option value="3">بنەڕەت</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                            <label className='mb-1'>پۆڵ</label>
                                            <select className="form-control" name="grade" required onChange={(e) => {
                                                if (e.target.value === '12') {
                                                    document.querySelector('select[name="question_round"]').innerHTML += '<option value="0">تەمهیدی</option>';
                                                } else {
                                                    document.querySelector('select[name="question_round"] option[value="0"]').remove();
                                                }
                                            }}>
                                                {grade.map((item, index) => {
                                                    return <option key={index} value={item}>{item}</option>
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                            <label className='mb-1'>بابەت</label>
                                            <select className="form-control" name="subject_id" required >
                                                {subject.map((item, index) => {
                                                    return <option key={index} value={item.id}>{item.subject}</option>
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                            <label className='mb-1'>جۆرێ پرسیار</label>
                                            <select className="form-control" name="question_type" required >
                                                <option value="ویزاری">ویزاری</option>
                                                <option value="ئەزموونی گشتی">ئەزموونی گشتی</option>
                                                <option value="دەرەکی">دەرەکی</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                            <label className='mb-1'>خوول</label>
                                            <select className="form-control" name="question_round" required >
                                                <option value={1}>خوولی یەکەم</option>
                                                <option value={2}>خوولی دووەم</option>
                                                <option value={0}>تەمهیدی</option>
                                                <option value={3}>بەندەکان</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                            <label className='mb-1'>بەند</label>
                                            <select className="form-control" name="unit" required>
                                                <option value='0'>گشتی</option>
                                                <option value="1">بەندی (١)</option>
                                                <option value="2">بەندی (٢)</option>
                                                <option value="3">بەندی (٣)</option>
                                                <option value="4">بەندی (٤)</option>
                                                <option value="5">بەندی (٥)</option>
                                                <option value="6">بەندی (٦)</option>
                                                <option value="7">بەندی (٧)</option>
                                                <option value="8">بەندی (٨)</option>
                                                <option value="9">بەندی (٩)</option>
                                                <option value="10">بەندی (١٠)</option>
                                                <option value="11">بەندی (١١)</option>
                                                <option value="12">بەندی (١٢)</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group mb-5 mt-40">
                                            <button type="submit" className="e-btn e-btn-7 w-100">پێشکێشکرن <i class=" far fa-check"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </div>
            <div className="container quizer" id="stage2" style={{ display: 'none' }}>
                <div className="text-right">
                    <h2>سالەکێ هەلبژێرە</h2>
                </div>
                <div className="row">
                    {season.length === 0 && <div className="col-lg-12 col-md-12">
                        <div className="alert alert-danger" role="alert">
                            ببورە لە ئیستادا هیچ پرسیارێک بەردەست نییە لەو هەڵبژاردنانەی کە دیارت کردوون !
                        </div>
                    </div>
                    }
                    {season.map(i => i.season).filter((value, index, self) => self.indexOf(value) === index).map((uniqueSeason, x) => {
                        return (
                            <div className="col-lg-3 col-sm-6" key={x}>
                                <div className="card single-categories">
                                    <div className="card-body categories-content-wrap" onClick={() => {
                                        axios.post(`/api/quizer`, { ...quizerData, season: uniqueSeason }).then((res) => {
                                            navigate(`/quiz/${res.data}`);
                                        })
                                    }}>
                                        <div className="categories-content">
                                            {uniqueSeason}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>

            
        </section >
    );
}

export default SubmitQuiz
