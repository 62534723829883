/* eslint-disable */
import { useEffect } from 'react';
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Submition = () => {
    const navigate = useNavigate();

    useEffect(() => {
    }, [document.title = `زانکوبادینان| فورما تومارکرنێ`])

    return (
        <main>
            <section className="event__area pt-150 pb-120 p-relative">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-7 col-xl-7 col-lg-7">
                            <div className="course__wrapper">
                                <div className="page__title-content mb-25">
                                    <h2 className="section__title">  پشکداریکرن ل <span className="yellow-bg"> فورما تومارکرن<img src="../../assets/img/yellow-bg.png" alt="sam" /></span></h2>
                                </div>
                                <div className="course__tab-content mb-95">
                                    <form onSubmit={(e) => {
                                        e.preventDefault();
                                        const formData = new FormData(e.target);
                                        const data = Object.fromEntries(formData);
                                        axios.post(`/api/submition`, data).then((res) => {
                                            toast.success('داخازیاتە هاتە پێشکێشکرن !');
                                            navigate(`/submition-result`);
                                        })
                                    }} >
                                        <div className="form-group mb-5">
                                            <label htmlFor="full_name" className='mb-1'>ناڤێ سیانی</label>
                                            <input type="text" className="form-control" name="full_name" placeholder='ناڤێ سیانی' required />
                                        </div>
                                        <div className="form-group mb-5">
                                            <label htmlFor="phone_no" className='mb-1'>ژ.مۆبایل</label>
                                            <input type="tel" className="form-control" name="phone_no" placeholder='ژ.مۆبایل' required />
                                        </div>
                                        <div className="form-group mb-5">
                                            <label htmlFor="department" className='mb-1'>پشک</label>
                                            <select className="form-control" name="department" required>
                                                <option value="زانستی">زانستی</option>
                                                <option value="وێژەیی">وێژەیی</option>
                                                <option value="پیشەیی">پیشەیی</option>
                                            </select>
                                        </div>
                                        <div className="form-group mb-5 mt-40">
                                            <button type="submit" className="e-btn e-btn-7 w-100">پێشکێشکرن <i class="far fa-check"></i></button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}

export default Submition;