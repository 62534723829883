import React, { useEffect, useState } from 'react';
import axios from "axios";
import { toast } from "react-toastify";

const SubmitionResult = () => {
    const [submitionResult, setSubmitionResult] = useState(null);
    const [event, setEvent] = useState(null);
    const [phoneNo, setPhoneNo] = useState('');
    const [allSubmitions, setAllSubmitions] = useState([]);

    useEffect(() => {
        document.title = 'زانکوبادینان | فورما تومارکرنێ';
        fetchAllSubmitions();
    }, []);

    useEffect(() => {
        if (submitionResult && allSubmitions.length > 0) {
            calculateEvent();
        }
    }, [submitionResult, allSubmitions]);

    const fetchAllSubmitions = async () => {
        try {
            const res = await axios.get('/api/submition-count');
            setAllSubmitions(res.data);
        } catch (error) {
            console.error("Error fetching all submissions:", error);
            toast.error("Error fetching data. Please try again.");
        }
    };

    const fetchSubmitionResult = async () => {
        try {
            const res = await axios.get(`/api/submition-result/${phoneNo}`);
            if (res.data && res.data.length > 0) {
                setSubmitionResult(res.data[0]);
            } else {
                toast.error("No submission found for this phone number.");
                setSubmitionResult(null);
            }
        } catch (error) {
            console.error("Error fetching submission result:", error);
            toast.error("Error fetching data. Please try again.");
        }
    };

    const calculateEvent = () => {
        const totalCount = allSubmitions.length;
        const partCount = 7;
        const partSize = Math.ceil(totalCount / partCount);

        const events = [
            'روژا ئێکێ',
            'روژا دووێ',
            'روژا سیێ',
            'روژا چارێ',
            'روژا پێنجێ',
            'روژا شەشێ',
            'روژا حەفتێ',
        ];

        const ranges = events.map((event, index) => ({
            limit: (index + 1) * partSize,
            event: event
        }));

        ranges.push({
            limit: totalCount,
            event: 'روژا دەهێ و دماهیک'
        });

        const submissionIndex = allSubmitions.findIndex(s => s.id === submitionResult.id);
        
        let foundEvent = null;
        for (let i = 0; i < ranges.length; i++) {
            if (submissionIndex < ranges[i].limit) {
                foundEvent = ranges[i].event;
                break;
            }
        }

        if (foundEvent) {
            setEvent(foundEvent);
        } else {
            console.warn("ببورە چ داتانەهاتە دیتن!");
            setEvent(null);
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        fetchSubmitionResult();
    }

    return (
        <main>
            <section className="event__area pt-150 pb-120 p-relative">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-7 col-xl-7 col-lg-7">
                            <div className="course__wrapper">
                                <div className="page__title-content mb-25">
                                    <h2 className="section__title">  پێزانین دەربارەی<span className="yellow-bg">  فۆرم داگرتن<img src="../../assets/img/yellow-bg.png" alt="sam" /></span></h2>
                                </div>
                                <div className="course__tab-content mb-95">
                                    <img src="../../uploads/resultsub.jpeg" alt="shape" className="shape shape-1" />
                                    <p>
                                        ناڤ و نیشانێ سەنتەرێ زانکۆلاین ب سەر پەرشتی مامۆستا سیروان بۆ داگرتنا فۆرما زانکۆلاین و پارالێل و ئێڤاران ل بەرامبەر دەرکێ زانکۆیا دهۆک.
                                        بۆ هەڤالێن خۆ پۆلا12فرێکەن.
                                    </p>

                                    {submitionResult && (
                                        <div className="form-group mb-5 mt-40">
                                            <p>ناڤێ سیانی: {submitionResult.full_name}</p>
                                            <p>ژ.مۆبایل: {submitionResult.phone_no}</p>
                                            <p>پشک: {submitionResult.department}</p>
                                            <p>ژڤانا داگرتنا فورمی : {event} </p>
                                            <small className='text-mute'>تێبینی: ل دویڤ بڕیارا وەزارەتێ ڤان روژا دێ هێنە دیارکرن</small>
                                        </div>
                                    )}
                                </div>
                                <div className="course__tab-content mb-95">
                                    <form onSubmit={handleSubmit}>
                                        <div className="form-group mb-5">
                                            <label htmlFor="phone_no" className='mb-1'>ژ.مۆبایل</label>
                                            <input
                                                type="tel"
                                                className="form-control"
                                                name="phone_no"
                                                placeholder='ژ.مۆبایل'
                                                required
                                                value={phoneNo}
                                                onChange={(e) => setPhoneNo(e.target.value)}
                                            />
                                        </div>
                                        <div className="form-group mb-5 mt-40">
                                            <button type="submit" className="e-btn e-btn-7 w-100">جێبەجێکرن <i className="far fa-check"></i></button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}

export default SubmitionResult;